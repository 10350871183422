import React from "react"
import { Link } from "gatsby"
import SidebarDivider from "../components/SidebarDivider"
import SidebarLink from "./SidebarLink"
import SidebarLinkHeading from "../components/SidebarLinkHeading"

export default () => {
  return (
    <>
      <li className="nav-item active">
        <Link to="/app/home">
          <p className="nav-link">
            <i className="fas fa-tachometer-alt"></i>
            <span>Dashboard</span>
          </p>{" "}
        </Link>
      </li>

      <SidebarDivider />
      <SidebarLinkHeading caption="Administer" />

      <SidebarLink
        caption="Clients"
        to="/app/administer-clients"
        icon="address-card"
      />

      <SidebarLink
        caption="Commissioning Bodies"
        to="/app/administer-commissioning-bodies"
        icon="address-book"
      />

      <SidebarLink
        caption="Locations"
        to="/app/administer-locations"
        icon="building"
      />
      <SidebarLink
        caption="Practitioners"
        to="/app/administer-practitioners"
        icon="chalkboard-teacher"
      />

      <SidebarLink
        caption="Projects"
        to="/app/administer-projects"
        icon="business-time"
      />

      {/* <SidebarLink
        caption="Sessions"
        to="/app/administer-projects"
        icon="drum"
      /> */}
      <SidebarDivider />
      <SidebarLinkHeading caption="Demo" />
      <SidebarLink
        caption="Release Notes"
        to="/app/release-notes"
        icon="sliders-h"
      />
      <SidebarDivider />
      <SidebarLinkHeading caption="System" />
      <SidebarLink caption="Help" to="/app/help" icon="question-circle" />
    </>
  )
}
