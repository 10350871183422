import React from "react"
import SidebarLink from "./SidebarLink"
import SidebarLinkHeading from "../components/SidebarLinkHeading"

export default () => {
  return (
    <>
      <SidebarLinkHeading caption="My" />

      <SidebarLink
        caption="Inbox"
        to="/app/home"
        icon="business-time"
      />
    </>
  )
}
