import React from "react"
import PropTypes from "prop-types"
import Sidebar from "../components/Sidebar"
import TopBar from "../components/TopBar"
import Footer from "../components/Footer"
import { ContextProviderComponent } from "./testContext"

const Layout = ({ children }) => {
  return (
    <>
      <div id="wrapper">
        <ContextProviderComponent>
          {/* <NavBar/> */}
          {/* Layout... */}
          <Sidebar gorole="admin" />
          <div id="content-wrapper" className="d-flex flex-column">
            <div id="content">
              <TopBar/>
              <div className="container-fluid">
                {/* <div className="row"> */}
                  <main>{children}</main>
                {/* </div> */}
                </div>
            </div>
            <Footer />
          </div>
        </ContextProviderComponent>
      </div>


      {/* Bootstrap core JavaScript*/}
      <script src="./vendor/jquery/jquery.min.js"></script>
      <script src="./vendor/bootstrap/js/bootstrap.bundle.min.js"></script>

      {/* Core plugin JavaScript*/}
      <script src="./vendor/jquery-easing/jquery.easing.min.js"></script>

      {/* Custom scripts for all pages*/}
      <script src="./js/sb-admin-2.min.js"></script>

      {/* Page level plugins */}
      <script src="./vendor/chart.js/Chart.min.js"></script>

      {/* Page level custom scripts */}
      <script src="./js/demo/chart-area-demo.js"></script>
      <script src="./js/demo/chart-pie-demo.js"></script>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout