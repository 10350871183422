import React, { useContext } from "react"
import { navigate } from "gatsby"
import FontAwesomeIcon from "../components/FontAwesomeIcon"
import UserContext from "../context/UserContext"
import PageContext from "../context/PageContext"
import ContextConsumer from "./testContext"

function TopBar() {
  const userContextValue = useContext(UserContext)

  const isLoggedIn = () => {
    return userContextValue.isLoggedIn()
  }

const getUserNameString=()=>{
  return (
    getUserName() 
  )
}

  const getUserName = () => {
    if (isLoggedIn() && userContextValue.user) {
      return userContextValue.user.email
    } else {
      return "Not logged in"
    }
  }

  const logUserOut = event => {
    event.preventDefault()
    userContextValue.logout()
    navigate(`/app/login`)
  }

  return (
    <>
      <ContextConsumer>
        {data => (
          <nav className="navbar navbar-expand navbar-light bg-white topbar mb-4 static-top shadow">
            <div className="d-sm-flex align-items-center justify-content-between mb-4">
            <PageContext.Consumer>
              {page => (
              <h2 className="h5 mb-0 text-gray-800">{page? page.name: "unknownp page"}</h2>
              )}
            </PageContext.Consumer>
            </div>

            <button
              id="sidebarToggleTop"
              className="btn btn-link d-md-none rounded-circle mr-3"
            >
              <i className="fa fa-bars"></i>
            </button>

            <ul className="navbar-nav ml-auto">
              {/* <TopBarSearch/> */}

              {/* Nav Item - Messages */}
              {/* <li className="nav-item dropdown no-arrow mx-1">
                <a
                  className="nav-link dropdown-toggle"
                  href="/"
                  id="messagesDropdown"
                  role="button"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  <i className="fas fa-envelope fa-fw"></i>
                  <span className="badge badge-danger badge-counter">
                    {data.data.inbox.count}
                  </span>
                </a>
              </li> */}

              <div className="topbar-divider d-none d-sm-block"></div>

              {/* Nav Item - User Information */}
              <li className="nav-item">
               

{isLoggedIn() ? (
                      <FontAwesomeIcon
                        icon="user"
                      />
                    ) : (``)}

                  
                  {/* {getUserName()} ({getUserRole()}) */}

                    {isLoggedIn()?(
                      <span className="mr-2 d-none d-lg-inline text-gray-600 small">
                    {getUserNameString() }
                    </span>
                    ):(
<span>Sign in:</span>
                    )}
                  

                  {isLoggedIn() ? (
                      <FontAwesomeIcon
                        icon="sign-out-alt"
                        tooltiptext="Sign out"
                        href="/"
                        onClick={event => logUserOut(event)}
                      />
                    ) : (
                      <FontAwesomeIcon
                        icon="sign-in-alt"
                        tooltiptext="Sign in "
                        href=""
                        onClick={event => {
                          event.preventDefault()
                          navigate(`/app/login`)
                        }}
                      />
                    )}


                  

                {/* Dropdown - User Information */}
                {/* <div
                  className="dropdown-menu dropdown-menu-right shadow animated--grow-in"
                  aria-labelledby="userDropdown"
                >
                  <a className="dropdown-item" href="/">
                    <i className="fas fa-user fa-sm fa-fw mr-2 text-gray-400"></i>
                    Profile
                  </a>
                  <a className="dropdown-item" href="/">
                    <i className="fas fa-cogs fa-sm fa-fw mr-2 text-gray-400"></i>
                    Settings
                  </a>
                  <a className="dropdown-item" href="/">
                    <i className="fas fa-list fa-sm fa-fw mr-2 text-gray-400"></i>
                    Activity Log
                  </a>
                  <div className="dropdown-divider"></div>
                  <a
                    className="dropdown-item"
                    href="/"
                    data-toggle="modal"
                    data-target="#logoutModal"
                  >
                    <i className="fas fa-sign-out-alt fa-sm fa-fw mr-2 text-gray-400"></i>
                    Logout
                  </a>
                </div> */}
              </li>
            </ul>
          </nav>
        )}
      </ContextConsumer>
    </>
  )
}

export default TopBar
