module.exports = [{
      plugin: require('U:/Users/dave/Documents/a_proj/maqro-app2/node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"gatsby-starter-default","short_name":"starter","start_url":"/","background_color":"#663399","theme_color":"#663399","display":"minimal-ui","icon":"src/images/icon-resized-#0F0.png"},
    },{
      plugin: require('U:/Users/dave/Documents/a_proj/maqro-app2/node_modules/gatsby-plugin-web-font-loader/gatsby-browser.js'),
      options: {"plugins":[],"google":{"families":["Nunito"]}},
    },{
      plugin: require('U:/Users/dave/Documents/a_proj/maqro-app2/node_modules/gatsby-plugin-layout/gatsby-browser.js'),
      options: {"plugins":[],"component":"U:\\Users\\dave\\Documents\\a_proj\\maqro-app2\\src\\components\\layout.js"},
    },{
      plugin: require('U:/Users/dave/Documents/a_proj/maqro-app2/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
