import React from "react"
import { Link } from "gatsby"
import LogoImage from "../components/imageIcon"

export default () => {
  return (
    <>
      <Link to="/app/home">
        <div className="sidebar-brand d-flex align-items-center justify-content-center">
          <div className="sidebar-brand-icon">
            <LogoImage />
          </div>
          <div className="sidebar-brand-text mx-3">GO Results</div>
        </div>
      </Link>
    </>
  )
}
