import React from "react"
import { Link } from "gatsby"
import FontAwesomeIcon from "../components/FontAwesomeIcon"

export default props => {
  return (
    <>
      <li className="nav-item">
        <Link to={props.to}>
          <p className="nav-link">
            <FontAwesomeIcon icon={props.icon} />
            <span>{props.caption}</span>
          </p>
        </Link>
      </li>
    </>
  )
}
