import React from "react"

const defaultState = {
  setPageName: () => {},
  name: "Welcome",
}

const PageContext = React.createContext(defaultState)

class PageProvider extends React.Component {
  state = {
    name: "Welcome!",
    }

  setName=(value)=>{
    if (this.state.name !== value){
      this.setState({ name:value })
    }
  }

  render() {
    const { children } = this.props
    return (
      <PageContext.Provider
        value={{
          setPageName: this.setName,
          name: this.state.name,
          setPageName200: this.setName,
        }}
      >
        {children}
      </PageContext.Provider>
    )
  }
}

export default PageContext

export { PageProvider }