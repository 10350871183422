// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("U:\\Users\\dave\\Documents\\a_proj\\maqro-app2\\src\\pages\\404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-administrator-cohort-progress-js": () => import("U:\\Users\\dave\\Documents\\a_proj\\maqro-app2\\src\\pages\\administrator-cohort-progress.js" /* webpackChunkName: "component---src-pages-administrator-cohort-progress-js" */),
  "component---src-pages-app-js": () => import("U:\\Users\\dave\\Documents\\a_proj\\maqro-app2\\src\\pages\\app.js" /* webpackChunkName: "component---src-pages-app-js" */),
  "component---src-pages-dev-testbed-js": () => import("U:\\Users\\dave\\Documents\\a_proj\\maqro-app2\\src\\pages\\dev-testbed.js" /* webpackChunkName: "component---src-pages-dev-testbed-js" */),
  "component---src-pages-index-js": () => import("U:\\Users\\dave\\Documents\\a_proj\\maqro-app2\\src\\pages\\index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-sessional-record-js": () => import("U:\\Users\\dave\\Documents\\a_proj\\maqro-app2\\src\\pages\\sessional-record.js" /* webpackChunkName: "component---src-pages-sessional-record-js" */)
}

