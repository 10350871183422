


class LogService{
    static log(value){
        console.log(value)
    }
    static logHttpResponse(value) {
        this.log("ERROR START");
        this.log(value);
        this.log(value.response);
        this.log("ERROR END");
     };

}
export default LogService