import React from "react"
import { navigate } from "gatsby"
import LogService from "../services/LogService";

const defaultState = {
  setUser: ()=>{},
  setToken: ()=>{},
  setReferenceData: ()=>{},
  setClients: ()=>{},
  isLoggedIn:()=>false,
  logout: ()=>{},
  Token: null,
  ReferenceData: null,
  Clients: null,
  user:null
}

const UserContext = React.createContext(defaultState)

class UserProvider extends React.Component {


  refreshTimestamps=[]

  state = {
    Token: null,
    ReferenceData: null,
    Clients: null,
    user:null
  }

  hasAdminRole=(roles)=>{
    var hasPermission=false;
    if (roles.indexOf("Administrator")>0){
      hasPermission=true;
    }
    return hasPermission
  }

  hasPractitionerRole=(roles)=>{
    var hasPermission=false;
    if (roles.indexOf("Practitioner")>0){
      hasPermission=true;
    }
    return hasPermission
  }

  canSeeAdminDashboard=(roles)=>{
    return this.hasAdminRole(roles)
  }
  canSeePractitionerInbox=(roles)=>{
    return this.hasPractitionerRole(roles)
  }

  getPermissions=(roles)=>{
    return {canSeeAdminDashboard:this.canSeeAdminDashboard(roles),
      canSeePractitionerInbox:this.canSeePractitionerInbox(roles),}
  }

  setReferenceData=(data, forceRefresh, timestamp)=>{
    //TODO: make this check a bit more testing 
    let doRefresh=false;
    if (forceRefresh && typeof (parseInt(timestamp))=='number' && this.refreshTimestamps.indexOf(timestamp)<0){
      this.refreshTimestamps.push(timestamp)
      doRefresh=true;
    }
    
    if (doRefresh || !this.state.ReferenceData || this.state.ReferenceData.projects.length !== data.projects.length){
      data.projects.forEach(proj => {
        proj.commissioningBodyName=this.getCommissioningBodyName(data, proj.commissioningBodyId)

        let clients = this.state.Clients
        var selectedClientIds = proj.clientIdString
          .substring(1, proj.clientIdString.length - 1)
          .split(",")
        let clientList = []
        for (var item of clients) {
          clientList.push({
            id: item.id,
            forename: item.forename,
            surname: item.surname,
            selected: selectedClientIds.indexOf(item.id) >= 0,
            originalSelected: selectedClientIds.indexOf(item.id) >= 0
          })
        }
        proj.clients=clientList;
      });
      this.setState({ReferenceData:data})    
    }else{
      LogService.log("Skipped Ref Data set (proj)")
    }
  }

  getCommissioningBodyName = (data, id) => {
      return data.commissioningBodies.find(a => a.id === id).name

  }

  setClients=(data)=>{
      this.setState({Clients:data.clients})    
  }
  setToken=(token)=>{
    this.setState({Token:token})
  }
  setUser=(user)=>{
    if (!this.state.user|| this.state.user.id !== user.id){
      this.setState({user:{
        clientRequestRef : user.clientRequestRef,
        email: user.email,
        orgShortName: user.orgShortName,
        orgKey: user.orgKey,
        Permissions : this.getPermissions(user.rolesString),
        rolesString : user.rolesString,
        role: user.role,
        id : user.id,
        rowSchemaVersion : user.rowSchemaVersion
      },
      Token: this.state.Token});
      navigate(`/app/home`)
    }
  }

  isLoggedIn=()=>{
    return this.state.user !=null
  }
  
  logout=()=>{
    this.setState({user:{},
      Token:null,
      ReferenceData: null,
      Clients: null});    
  }

  render() {
    const { children } = this.props
    return (
      <UserContext.Provider
        value={{
          setUser: this.setUser,
          setToken: this.setToken,
          setReferenceData: this.setReferenceData,
          setClients: this.setClients,
          logout: this.logout,
          Token: this.state.Token,
          ReferenceData: this.state.ReferenceData,
          Clients: this.state.Clients,
          isLoggedIn: this.isLoggedIn,
          user:this.state.user
        }}
      >
        {children}
      </UserContext.Provider>
    )
  }
}

export default UserContext

export { UserProvider }