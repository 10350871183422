import "./src/styles/bootstrap.4.3.1.min.css"
import "./src/styles/sb-admin-2.css"


import React from "react"

import { ThemeProvider } from "./src/context/ThemeContext"
import { UserProvider } from "./src/context/UserContext"
import { PageProvider } from "./src/context/PageContext"

export const wrapRootElement = ({ element }) => (
    <PageProvider><UserProvider><ThemeProvider>{element}</ThemeProvider></UserProvider></PageProvider>
)