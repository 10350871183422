import React from "react"

export default props => {
  return (
    <>
      <div className="sidebar-heading">
          {props.caption}
        </div>

    </>
  )
}
