import React from "react"
import SidebarBrand from "../components/SidebarBrand"
import SidebarDivider from "../components/SidebarDivider"
import UserContext from "../context/UserContext"
import SidebarAdministratorMenu from "../components/SidebarAdministratorMenu"
import SidebarPractitionerMenu from "../components/SidebarPractitionerMenu"

const SidebarComponent = () => {
  return (
    <>
      <ul
        className={`navbar-nav bg-gradient-primary-admin sidebar sidebar-dark accordion`}
        id="accordionSidebar"
      >
        <SidebarBrand />
        <SidebarDivider />

        <UserContext.Consumer>
          {user =>
            user != null &&
            user.user != null &&
            user.user.Permissions != null ? (
              user.user.Permissions.canSeeAdminDashboard ? (
                <SidebarAdministratorMenu />
              ) : (
                <SidebarPractitionerMenu />
              )
            ) : null
          }
        </UserContext.Consumer>
      </ul>
    </>
  )
}

export default SidebarComponent
