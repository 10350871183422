import React from "react"

const defaultContextValue = {
  data: {
    // set your initial data shape here
    menuOpen: false,
    name: "dave",
    pagename: "Pract Inbox (testcontextjs)  ",
    inbox:{count:16}
  },
  set: () => {},
  setPageName: ()=>{},
}

const ctx= React.createContext(defaultContextValue)

const { Provider, Consumer } = ctx;

class ContextProviderComponent extends React.Component {
  constructor() {
    super()

    this.setData = this.setData.bind(this)
    this.state = {
      ...defaultContextValue,
      set: this.setData,
      setPn:this.setPageName  
    }
    this.setPageName= this.setPageName.bind(this)
  }

  setData(newData) {
    this.setState(state => ({
      data: {
        ...state.data,
        ...newData,
      },
    }))
  }

  setPageName(name){
    this.setState({pageName:name});
  }
  render() {
    return <Provider value={this.state}>{this.props.children}</Provider>
  }
}

export { Consumer as default, ContextProviderComponent, Provider, ctx as Ctxt }